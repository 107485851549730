<template>
    <div class="container_main">
        <div class="search_form">
            <Form class="ant-advanced-search-form" :form_data="form_data_seo" @handleSubmit="handleSubmit" @handleReset="handleReset" ref="form_seo"></Form>
        </div>
        <div class="list_main">
            <div class="list_left">
                <div class="left_main">
                    <div class="FieldSettingsBox">
						<div class="FieldSettingsBoxTitle ">
							<span class="show_type">指标分类</span>
							<span class='show_type_setting' @click="treeSelectItem('','add')">新增分类</span>
						</div>
                        <div style="padding:10px 0;">
                            <treeList :subInfo="classifyListData" :expandedKeys="expandedKeys" :selectedKeys="selectedKeys" :opStatus="true" @select='treeSelectItem'></treeList>
                        </div>
					</div>
                </div>
            </div>
            <div class="list_right">
                <TableList :columns="columns" :get_table_list="get_table_list" :where="where" :slot_table_list="['operation','status']" ref="list" :rowKey="'id'" :style_mode="''" >
                    <template slot="left_btn">
                        <a-button @click="getAddSave({id:''})" type="primary">新增指标</a-button>
                    </template>
                    <template slot="status" slot-scope="data">
                        <span v-if="data.record.status == 1" class="status_box" @click="getQuotaStatus(data.record)">启用</span>
                        <span v-else class="status_box" style="background:red;" @click="getQuotaStatus(data.record)">禁用</span>
                    </template>
                    <template slot="operation" slot-scope="data">
                        <a @click="getAddSave(data.record)">编辑</a>
                        <a-divider type="vertical" />
                        <a @click="getDelete(data.record)">删除</a>
                    </template>
                </TableList>
            </div>
        </div>

        <!-- 添加或编辑指标分类 -->
        <a-modal v-model:visible="classifyShow" :title="classifyFrom.id ? '编辑指标分类信息' : '新增指标分类信息'" @ok="classifyOk">
            <classifyAddSaveVue :classifyListData="classifyListData" :materialList="materialList" :classifyFromData="classifyFrom" ref="classifyFrom" ></classifyAddSaveVue>
        </a-modal>
    </div>
</template>

<script>
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import treeList from '../components/treeList.vue'
import classifyAddSaveVue from '../components/classifyAddSave.vue'
import {message,Modal} from 'ant-design-vue';
import {
    getQuotaInfoList,
    getQuotaCateList,
    getMaterialListNoPage,
    delQuotaCate,
    saveQuotaCate,
    changeQuotaStatus,
    delQuotaInfo
} from '@/api/qms.js'

const columns = [{
        title: "检测项目",
        dataIndex: "name",
    },
    {
        title: "方案",
        dataIndex: "programme",
    },
    {
        title: "检测水平",
        dataIndex: "detection_level",
    },
    {
        title: "所属分类",
        dataIndex: "cate_name",
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

export default{
    components: {
        Form,
        TableList,
        treeList,
        classifyAddSaveVue
    },
    data() {
        return {
            get_table_list:getQuotaInfoList,
            columns,
            where:{
                cate_id:'',
                keyword:'',
            },
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "请输入指标检测关键词",
                        options: {}
                    },
                ],
            },

            expandedKeys: [],
			selectedKeys: [],
            classifyListData: [],
            materialList:[],
            classifyShow:false,
            classifyFrom:{
                id:'',
                name:'',
                pid:'',
                goods_related:''
            },
            
        }
    },
    created() {
        this.getMaterialList()
        this.getQuotaCateList()
    },
    methods: {
        getQuotaCateList(){
            getQuotaCateList({}).then(res=>{
                this.classifyListData = res.data.list
            })
        },

        getMaterialList(){
            getMaterialListNoPage({
                is_page:-1, //是否分页 1是-1否
                is_select:1 //是否选择框信息 1是-1否
            }).then(res=>{
                this.materialList = res.data.list
            })
        },
        
        handleReset() {
            this.where.keyword = ''
            this.where.cate_id = ''
            this.selectedKeys = []
            this.$refs.list.get_list()
        },
        handleSubmit(e) {
            this.where.keyword = e.values.keyword
            this.$refs.list.get_list()
        },
        treeSelectItem(info,type) {
            //选中数据
            if(type == 'title'){
                let data = []
                data.push(info.id)
                this.selectedKeys = data
                this.where.cate_id = info.id
				this.$refs.list.pagination.page=1
                this.$refs.list.get_list()
            }
            //展开或收起
            if(type == 'updown'){
                if(this.expandedKeys.indexOf(info.id) > -1){
                    let expandedData = this.expandedKeys.filter( item => {
                        return item != info.id
                    })
                    this.expandedKeys = expandedData
                }else{
                    this.expandedKeys.push(info.id)
                }
            }
            //编辑数据
            if(type == 'add'){
                this.classifyFrom = {pid:[],name:'',goods_related:''}
                this.classifyShow = true
            }
            //编辑数据
            if(type == 'edit'){
                this.classifyFrom = {
                    id:info.id,
                    pid:info.pid > 0 ? info.pid : [],
                    name:info.title,
                    goods_related:info.goods_related
                }
                this.classifyShow = true
            }
            //删除数据
            if(type == 'delete'){
                let that = this
                Modal.confirm({
                    title: '温馨提示',
                    content: '确定要删除指标分类【'+info.title+'】吗？',
                    okText: '确定',
                    cancelText: '取消',
                    onOk() {
                        delQuotaCate({
                            id:info.id
                        }).then(res=>{
                            message.success('删除成功');
                            that.getQuotaCateList()
                        })
                    }
                });
            }
        },
        classifyOk(){
            this.$refs.classifyFrom.getSubmit().then(res=>{
                saveQuotaCate({
                    ...res
                }).then(res=>{
                    this.classifyShow = false
                    message.success('保存成功');
                    this.getQuotaCateList()
                })
            })
        },

        getQuotaStatus(info){
            let text = info.status == 1 ? "禁用" : "启用"
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要'+text+'指标【'+info.name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    changeQuotaStatus({
                        id:info.id
                    }).then(res=>{
                        message.success('操作成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getDelete(info){
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除指标【'+info.name+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    delQuotaInfo({
                        id:info.id
                    }).then(res=>{
                        message.success('删除成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getAddSave(info){
            this.$router.push({path: '/qmsQuota/addSave',query: {id: info.id}})
        },
       
    }
}
</script>

<style lang="less" scoped>
.container_main{
    padding:15px;
}

.search_form{
    padding:15px 15px 0px 15px;
    background:#ffffff;
}

.list_main{
    margin-top:15px;
    display:flex;
    background:#ffffff;

    .list_left{
        width:260px;

        .left_main{
            width:100%;
        }
    }

    .list_right{
        width:calc(100% - 260px);
        padding:15px;
    }
}

.FieldSettingsBox {
    border: none;
    border-right: 1px solid #eeeeee;
    margin-top: 12px;
    height: auto;
    padding-left:15px;
}

.FieldSettingsBoxTitle {
    display: flex;
    width: 100%;
    padding: 8px 12px 8px 0px;
    border-bottom: 1px solid #eeeeee;
    justify-content:space-between;
}

.show_type {
    border-left: 3px solid #0079FF;
    padding-left: 10px;
    font-weight:bold;
}

.show_type_setting {
    text-align: right;
    color:#397dee;
    cursor:pointer;
}

.status_box{
    background:#1890ff;
    padding:1px 5px 3px 5px;
    color:#ffffff;
    border-radius:2px;
    font-size:12px;
    cursor:pointer;
    display:inline-block;
    min-width:34px;
}

/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon {
    color: #666;
    font-size: 16px;
}

/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
    color: #333;
    font-size: 16px;
}

/deep/.ant-tree-node-content-wrapper{
    width:calc(100% - 36px);
}

.tree_title_main{
    width:100%;
    position: relative;

    .title_op{
        display:flex;
        position: absolute;
        top:0;
        right:0;
        background:red;
    }
}

</style>
